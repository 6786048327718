import { getUserContext } from "./LocalStorageUserContextUtil";
import { detect } from 'detect-browser';

export const resolveHome =  (userType: string) => {
    const userContext = getUserContext();
    // TO DO: add artist profile page
    return "/Landing";
}

export const detectDesktop = () => {
    const browser = detect();
    // console.log( browser );
    const browserOs = (browser!=null&&browser.os !=null) ?(browser.os).toLocaleLowerCase().split(" ") : null;
    // console.log( browserOs );
    const isDesktop = (browserOs == null) ? true : !( browserOs.includes('android') || browserOs.includes('ios') );

    const isSafari = (browserOs == null) ? false : ( ( browserOs.includes('mac') && browserOs.includes('os')) && (browser?.name.includes('safari') || browser?.name.includes('chrome')) )
    // console.log( isSafari );

    const isWindow = (browserOs == null) ? false : browserOs.includes('windows') && browser?.name.includes('chrome')
    return { isDesktop, isSafari, isWindow };
    // return false
}

export const detectWebView = () => {
    const browser = detect();
    
    const browserName = browser?.name
    const isConfirmedWebView = browserName=='chromium-webview' || 
                        browserName=='facebook' || 
                        browserName=='instagram' || 
                        browserName=='ios-webview'
                         ? true : false

    const isPotentialWebView = browserName=='ios' || browserName=='samsung' ? true : false

    return { isConfirmedWebView, isPotentialWebView, os: browser?.os }
}